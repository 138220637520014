import React from 'react';
import InfrastructureMap from '../../../ui/InfrastructureMap';

import {
  INFRASTRUCTURE_MAP,
  INFRASTRUCTURE_CHART,
  INFRASTRUCTURE_FILTERS,
  INFRASTRUCTURE_INDICATOR
} from './queries';

const InfrastructureMapContainer = () => {
  const settings = {
    queries: {
      map: INFRASTRUCTURE_MAP,
      chart: INFRASTRUCTURE_CHART,
      filters: INFRASTRUCTURE_FILTERS,
      indicator: INFRASTRUCTURE_INDICATOR
    },
    queryPrefix: 'townInfrastructure',
    defaultFilters: {
      townId: null
    },
    defaultVariables: {}
  };

  return (
    <InfrastructureMap {...settings} />
  );
}

export default InfrastructureMapContainer;
