import React, { PureComponent } from 'react';

class Legend extends PureComponent {
  get hasLabels(){
    return this.props.labels.length > 0;
  }

  renderLabels(){
    const step =  this.hasLabels ? (100 / (this.props.labels.length - 1)) : 1 ;

    return (
      <div className='GeoserverLegendLables'>
        {this.props.labels.map((label, index)=>{
          return (
            <div
              key={`legend_${index}`}
              className='GeoserverLegendLablesItem'
              style={{ left: `${(step * index)}%` }}
            >
              {label}
              <small>%</small>
            </div>
          );
        })}
      </div>
    )
  }

  renderPalette(){
    return (
      <div
        className='GeoserverLegendPalette'
        style={{ background: `linear-gradient(90deg, ${this.props.palette.join(', ')})` }}
      >
      </div>
    )
  }

  render(){
    if(!this.hasLabels) { return ''; }
    let classes = 'GeoserverLegend';

    if(this.props.loading){
      classes = `${classes} GeoserverLegendLoading`
    }

    return (
      <div className={classes}>
        <div className='GeoserverLegendTitle'>
          Percentual de oportunidades
        </div>
        {this.renderPalette()}
        {this.renderLabels()}
      </div>
    )
  }
}

export default Legend;
