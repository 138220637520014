import React from 'react';
import InfrastructureMap from '../../../ui/InfrastructureMap';

import {
  INFRASTRUCTURE_MAP,
  INFRASTRUCTURE_CHART,
  INFRASTRUCTURE_FILTERS,
  INFRASTRUCTURE_INDICATOR
} from './queries';

const InfrastructureMapContainer = ({ mr }) => {
  const settings = {
    queries: {
      map: INFRASTRUCTURE_MAP,
      chart: INFRASTRUCTURE_CHART,
      filters: INFRASTRUCTURE_FILTERS,
      indicator: INFRASTRUCTURE_INDICATOR
    },
    queryPrefix: 'metropolitanRegionInfrastructure',
    defaultFilters: {},
    defaultVariables: {
      abbrev: mr
    }
  };

  return (
    <InfrastructureMap {...settings} />
  );
}

export default InfrastructureMapContainer;
