import React, { Component } from 'react';
import PropTypes from 'prop-types';

import mapboxgl from 'mapbox-gl';

import BaseMapSwitcher from './BaseMapSwitcher';
import MetadataContainer from './MetadataContainer';
import Legend from './Legend';
import GeoserverLegend from './GeoserverLegend';

import { addIcons } from './icons';

mapboxgl.accessToken = 'pk.eyJ1IjoiZWNvc3RhZ2UiLCJhIjoiY2lyNmZ4Y283MDBicWczbTF6ZzNhMTZwZSJ9.Kmm34ZwjLp3n08a3YVB1Mg'

class Map extends Component {
  // config
  baseMap = this.props['baseMap'];
  center = this.props['center'];
  zoom = this.props['zoom'];

  styledLayers = [];
  dataLayers = [];
  legends = []
  geoserverLegends = []
  allowedKeys = []

  state = {
    legends: [],
    geoserverLegends: [],
    baseMap: this.props['baseMap']
  };

  get currentStyleUrl() {
    return `mapbox://styles/mapbox/${this.baseMap}`;
  }

  get currentCenter() {
    return Object.values(this.map.getCenter());
  }

  get mapConfig() {
    return {
      minZoom: 3,
      maxZoom: 16,
      pitchWithRotate: false,
      dragRotate: false,
      touchZoomRotate: false,
      container: this.refs.map,
      style: this.currentStyleUrl,
      interactive: this.props.interactive,
      preserveDrawingBuffer: true,
      renderingMode: "2d",
      attributionControl: false,
      center: this.center,
      zoom: this.zoom
    };
  }

  changeBaseMap(baseMap) {
    this.baseMap = baseMap;
    this.setState({ baseMap })
    this.refreshStyle();
    this.loadLayers();
  }

  loadMap() {
    this.map = new mapboxgl.Map(this.mapConfig);

    if(this.props.interactive && this.props.controls ) {
      this.map.addControl(new mapboxgl.NavigationControl({
        visualizePitch: false,
        showCompass: false
      }));
    }

    this.map.on('style.load', this.loadLayers.bind(this));

    this.map.on('error', () => { });
  }

  unbindHoverEvents() {
    this.dataLayers.forEach((layerId) => {
      this.map.off('mousemove', layerId);
      this.map.off('click', layerId);
      this.map.off('touchend', layerId);
      this.map.off('mouseleave', layerId);
      this.map.off('touchstart', layerId);
    });
  }

  bindHoverEvents() {
    const { onMouseOver, onMouseLeave, onClick } = this.props;
    const mouseOverHandler = (event) => {
      onMouseOver && onMouseOver(event, this.map, this);
      this.handleMapEvent(event)
    };
    const mouseLeaveHandler = (event) => {
      onMouseLeave && onMouseLeave(event, this.map, this);
      this.handleMapEvent(event);
    };

    const clickHandler = (event) => {
      onClick && onClick(event, this.map, this);
    };

    this.dataLayers.forEach((layerId) => {
      this.map.on('mousemove', layerId, mouseOverHandler);
      this.map.on('click', layerId, clickHandler);
      this.map.on('touchend', layerId, clickHandler);
      this.map.on('touchstart', layerId, mouseOverHandler);
      this.map.on('mouseleave', layerId, mouseLeaveHandler);
    })
  }

  removeLayer(layerId) {
    if(this.map.getLayer(layerId)) {
      this.map.removeLayer(layerId);
    }
  }

  clearLayers(collectionKey) {
    this[collectionKey].forEach((layer) => this.removeLayer(layer));
    this[collectionKey] = [];
  }

  appendLegend(legend) {
    this.legends = [...legend, ...this.legends];
  }

  appendGeoserverLegend(legend) {
    this.geoserverLegends = [legend, ...this.geoserverLegends];
  }

  appendAllowedKeys(allowedKeys) {
    this.allowedKeys = [...allowedKeys, ...this.allowedKeys].sort((a, b) => a.ordering - b.ordering);
  }

  appendLayer(layers) {
    if(layers) {
      return layers.map(({ name, source, layer, legend, allowedKeys, geoserverLegend }) => {
        !this.map.getSource(name) && this.map.addSource(name, source);

        this.map.addLayer(layer);

        legend && this.appendLegend(legend);
        geoserverLegend && this.appendGeoserverLegend(geoserverLegend);
        allowedKeys && this.appendAllowedKeys(allowedKeys);

        return name;
      });
    }

    return [];
  }

  loadDataLayers() {
    this.dataLayers = this.appendLayer(this.props.dataLayers);
  }

  loadStyledLayers() {
    this.styledLayers = this.appendLayer(this.props.styledLayers);
  }

  highlightFeature(features) {
    this.clearHighlightFeature();

    features.forEach((feature) => {
      if(feature['id'] && feature['id'] !== null) {
        this.map.setFeatureState({
          source: feature['source'],
          sourceLayer: feature['sourceLayer'],
          id: feature['id']
        }, {
          hover: true
        });
      }
    });

    this.setState({ selectedFeatures: features });
  }

  clearHighlightFeature() {
    const { selectedFeatures } = this.state;

    if(selectedFeatures && selectedFeatures.length > 0) {
      selectedFeatures.forEach((feature) => {
        if(feature['id'] && feature['id'] !== null) {
          this.map.setFeatureState({
            source: feature['source'],
            sourceLayer: feature['sourceLayer'],
            id: feature['id']
          }, {
            hover: false
          });
        }
      });

      this.setState({ selectedFeatures: null });
    }
  }

  handleMapEvent({ features, point, lngLat }) {
    if (features && features.length > 0) {
      if(this.isDiffObject(this.state.selectedFeatures, features)) {
        this.highlightFeature(features);
      }
    } else {
      // this.clearHighlightFeature();
    }
  }

  componentDidMount() {
    // delay the load on mount to ensure render
    setTimeout(() => this.loadMap(), 400);
  }

  isDiffLayers(prevLayers, newLayers) {
    const prevLayersNames = (prevLayers || []).map(({name}) => (name)).sort();
    const newLayersNames = (newLayers || []).map(({name}) => (name)).sort();

    return prevLayersNames.toString() !== newLayersNames.toString();
  }

  isDiffObject(a, b) {
    return JSON.stringify(a) !== JSON.stringify(b)
  }

  componentDidUpdate({ dataLayers, styledLayers, zoom, center }) {
    const shouldReloadMap = this.isDiffLayers(dataLayers, this.props.dataLayers) ||
                          this.isDiffLayers(styledLayers, this.props.styledLayers)
    if(shouldReloadMap) {
      this.map && this.loadLayers();
    }

    if(zoom !== this.props.zoom) {
      this.zoom = this.props.zoom;
      this.map.setZoom(this.props.zoom);
    }

    if(center.toString() !== this.props.center.toString()) {
      this.center = this.props.center;
      this.map.setCenter(this.props.center);
    }
  }

  refreshLegend() {
    if(this.isDiffObject(this.legends, this.state.legends)) {
      this.setState({ legends: this.legends });
    }
  }

  refreshGeoserverLegend() {
    if(this.isDiffObject(this.geoserverLegends, this.state.geoserverLegends)) {
      this.setState({ geoserverLegends: this.geoserverLegends });
    }
  }

  refreshStyle() {
    this.map.setStyle(this.currentStyleUrl);
  }

  loadLayers() {
    addIcons(this.map);

    this.legends = [];
    this.geoserverLegend = [];
    this.allowedKeys = [];

    this.clearLayers('styledLayers');
    this.loadStyledLayers();

    this.clearLayers('dataLayers');
    this.unbindHoverEvents();
    this.loadDataLayers();
    this.bindHoverEvents();

    this.refreshLegend();
    this.refreshGeoserverLegend();
  }

  renderLegend() {
    if(this.state.legends.length > 0) {
      return (
        <Legend items={this.state.legends}>
          {this.renderGeoserverLegend()}
        </Legend>
      );
    }
  }

  renderGeoserverLegend() {
    if(this.state.geoserverLegends.length > 0) {
      return (<GeoserverLegend {...this.state.geoserverLegends[0]} />);
    }
  }

  renderBaseMapSwitcher() {
    if(this.props.mapSwitcher) {
      return (<BaseMapSwitcher
        onChangeBaseMap={this.changeBaseMap.bind(this)}
        baseMap={this.state.baseMap} />)
    }
  }

  renderMetadataPopup() {
    if(this.state.selectedFeatures) {
      if(this.props.metadataRender) {
        return this.props.metadataRender(this.state.selectedFeatures);
      } else {
        return (
          <MetadataContainer
            allowedKeys={this.allowedKeys}
            features={this.state.selectedFeatures}
          />
        );
      }
    }
  }

  render() {
    return (
      <div className='Map-Wrapper'>
        <div ref="map" className='Map'></div>

        <div className='Map-data'>
          {this.renderMetadataPopup()}
          {this.renderLegend()}
          {this.renderBaseMapSwitcher()}
        </div>

        {this.props.children}
      </div>
    )
  }
}

Map.propTypes = {
  baseMap: PropTypes.string,
  center: PropTypes.array,
  zoom: PropTypes.number,
  interactive: PropTypes.bool,
  controls: PropTypes.bool,
  mapSwitcher: PropTypes.bool,
  dataLayers: PropTypes.array,
  styledLayers: PropTypes.array
};

Map.defaultProps = {
  baseMap: 'dark-v9',
  center: [-43.9344931, -19.9166813],
  zoom: 7,
  interactive: true,
  controls: true,
  mapSwitcher: false,
  dataLayers: [],
  styledLayers: []
};

export default Map;
