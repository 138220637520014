import React from 'react';
import { safeRender } from '../../render';

import Header from  './Header';
// import AccessibilityMap from  './AccessibilityMap';
import InfrastructureMap from  './InfrastructureMap';
import HistoricalSeries from  './HistoricalSeries';

safeRender('#town-header', Header);
// safeRender('#town-accessibility-map', AccessibilityMap);
safeRender('#town-infrastructure-map', InfrastructureMap);
safeRender('#town-historical-series', HistoricalSeries);
