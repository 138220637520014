import React from 'react';
import Chart from '../../../ui/Chart';
import Loading from '../../../ui/Loading';
import { useQuery } from '@apollo/client';
import { numberFormat } from '../../../libs/number_format';

import { DENSITY_CHART } from './queries';

const DensitiyChart = ({ mr }) => {
  const { loading, error, data } = useQuery(DENSITY_CHART, {
    variables: { abbrev: mr },
  });

  if (loading) return (<Loading/>);

  const settings = {
    title: 'Densidade',
    type: 'bar',
    data: data['metropolitanRegionDensityChart'],
    options: {
      legend: {
        display: false
      },
      tooltips: {
        callbacks: {
          label: (tooltipItems, data) => {
            return `${numberFormat(tooltipItems.yLabel)} Hab./km²`;
          }
        }
      },
      scales: {
        xAxes: [{
          scaleLabel: {
            display: true,
            fontColor: 'rgba(255, 255, 255)'
          },
          ticks: {
            fontColor: 'rgba(255, 255, 255)',
          }
        }],
        yAxes: [{
          scaleLabel: {
            display: true,
            fontColor: 'rgba(255, 255, 255)'
          },
          ticks: {
            fontColor: 'rgba(255, 255, 255)',
            beginAtZero: true,
            callback: function(value, index, values) {
              return numberFormat(parseFloat(value));
            }
          }
        }]
      }
    }
  }

  return <Chart settings={settings} />
}

export default DensitiyChart;
