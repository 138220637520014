import { gql } from '@apollo/client';

const INFRASTRUCTURE_MAP = gql`
  query townInfrastructureMap($townId: Int!, $indicatorId: Int) {
    townInfrastructureMap(townId: $townId, indicatorId: $indicatorId) {
      dataLayers {
        name
        source
        layer
        legend
        allowedKeys
      }

      styledLayers {
        name
        source
        layer
        legend
      }

      center
      zoom
    }
  }
`;

const INFRASTRUCTURE_CHART = gql`
  query townInfrastructureChart($townId: Int!, $indicatorId: Int!) {
    townInfrastructureChart(townId: $townId, indicatorId: $indicatorId) {
      labels
      datasets
    }
  }
`;

const INFRASTRUCTURE_FILTERS = gql`
  query townInfrastructureFilters($townId: Int) {
    townInfrastructureFilters(townId: $townId)
  }
`;

const INFRASTRUCTURE_INDICATOR = gql`
  query townInfrastructureIndicator($indicatorId: Int!) {
    indicatorGroup(id: $indicatorId)
  }
`;

export {
  INFRASTRUCTURE_MAP,
  INFRASTRUCTURE_CHART,
  INFRASTRUCTURE_FILTERS,
  INFRASTRUCTURE_INDICATOR
}
