import { controllers } from 'chart.js';

class DumbbellController extends controllers.line {
}


DumbbellController.id = 'dumbbell';

const defaultGridLineStyle = {
  color: 'rgba(255, 255, 255, 0.3)'
};

const defaultTickStyle = {
  fontColor: 'rgb(255, 255, 255)'
};


DumbbellController.defaults = {
	scales: {
    xAxes: [{
      type: 'linear',
      gridLines: {
        ...defaultGridLineStyle,
        display: true,
      },
      scaleLabel: {
        display: true,
        fontColor: 'rgba(255, 255, 255)'
      },
      ticks: {
        ...defaultTickStyle,
        beginAtZero: true,
        suggestedMin: 0


      }
    }],
    yAxes: [{
      type: "category",
      position: 'bottom',
      gridLines: {
        display: true,
        ...defaultGridLineStyle,
      },
      // scaleLabel: {
      //   display: false,
      //   fontColor: 'rgba(255, 255, 255)'
      // },
      ticks: {
        ...defaultTickStyle,
        beginAtZero: true,
      },
    }]
  },
  //
	showLine: false,

  datasets: {
    showLine: false,
    fill: false
  },
};

export const addController = () => {
  const id = DumbbellController.id;

  Chart.defaults[id] = DumbbellController.defaults;
  Chart.controllers[id] = DumbbellController;
}
