import React,  { Component } from 'react';
import ModalBox from '../../../ui/ModalBox';
import { numberFormat } from '../../../libs/number_format';
import { client } from  '../../../configs/apollo';
import { TOWN_HEADER_DATA } from './queries';
import Loading from '../../../ui/Loading';

class TownCard extends Component {
  state = {
    tab: 'info',
    data: null
  }

  componentDidMount() {
    client.query({
      query: TOWN_HEADER_DATA,
      variables: { id: this.props.data.id }
    }).then(({ data: { town: data } }) => {
      if(!data) { return; }
      this.setState({ data });
    });
  }

  renderDataLineItem(value, label) {
    return (
      <div className="mobilidados-platform-capital-info__dataline-item">
        {value}

        <div className="mobilidados-platform-capital-info__dataline-item-label">
          {label}
        </div>
      </div>
    )
  }

  renderBasicInfo() {
    const { data } = this.state;

    return [
      [`${data.area} ${this.props.translations.info.area_unit}`, this.props.translations.info.area],
      [`${data.idhm.toFixed(3)} ${this.props.translations.info.idhm_unit}`, this.props.translations.info.idhm],
      [`${numberFormat(data.population)} ${this.props.translations.info.population_unit}`, this.props.translations.info.population],
      [`${numberFormat(data.urbanDensity.toFixed(2))} ${this.props.translations.info.urban_density_unit}`, this.props.translations.info.urban_density]
    ].map((item)=>{
      return this.renderDataLineItem(item[0], item[1]);
    });
  }

  renderInfo() {
    const { data } = this.state;
    return (
      <div className="mobilidados-platform-capital-info-tab__content">
        <div className="mobilidados-platform-capital-info__dataline">
          {this.renderBasicInfo()}
        </div>

        <div className="mobilidados-platform-capital-info__dataline">
          <div className="mobilidados-platform-capital-info__dataline-item">
            {parseInt(data.percDrLess1sm * 100)} {this.props.translations.info.perc_dr_less_1sm_unit}

            <div className="mobilidados-platform-capital-info__dataline-item-label">
              {this.props.translations.info.perc_dr_less_1sm}
            </div>
          </div>

          <div className="mobilidados-platform-capital-info__dataline-item">
            {parseInt(data.percNegros * 100)} {this.props.translations.info.perc_negros_unit}

            <div className="mobilidados-platform-capital-info__dataline-item-label">
              {this.props.translations.info.perc_negros}
            </div>
          </div>

          <div className="mobilidados-platform-capital-info__dataline-item">
            {parseInt(data.percMulheres * 100)} {this.props.translations.info.perc_mulheres_unit}

            <div className="mobilidados-platform-capital-info__dataline-item-label">
              {this.props.translations.info.perc_mulheres}
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderSource(source) {
    if(source.includes('http://') || source.includes('https://')) {
      return (
        <a href={source} target="_blank">
          <i className="material-icons">link</i>
        </a>
      );
    }

    return source;
  }

  renderLAITransportContract() {
    const { data } = this.state;

    if (data.laiContrato != '') {
      return (
        <div className="data green half-length">
          <div className="data-header">
            {this.props.translations.mobility.lai_contrato_title}
          </div>
          <div className="data-info">
            <div className="data-info-item">
              <div className="data-info-item-value">
                {data.laiContratoInicio}
              </div>

              <div className="data-info-item-label">
                {this.props.translations.mobility.lai_contrato_inicio}
              </div>
            </div>

            <div className="data-info-item">
              <div className="data-info-item-value">
                {data.laiContratoPrazo}
              </div>

              <div className="data-info-item-label">
                {this.props.translations.mobility.lai_contrato_prazo}
              </div>
            </div>
          </div>

          <div className="data-source">
            {this.renderSource(data.laiContratoFonte)}
          </div>
        </div>
      );
    }
  }

  renderLAIGPS() {
    const { data } = this.state;

    if (data.laiGps != '') {
      return (
        <div className="data green half-length">
          <div className="data-header">
            {this.props.translations.mobility.lai_gps_title}
          </div>
          <div className="data-info">
            <div className="data-info-item">
              <div className="data-info-item-value">
                {data.laiGpsFrota}
              </div>

              <div className="data-info-item-label">
                {this.props.translations.mobility.lai_gps_fleet}
              </div>
            </div>
          </div>

          <div className="data-source">
            {this.renderSource(data.laiGpsFonte)}
          </div>
        </div>
      );
    }
  }

  renderLAIGTFS() {
    const { data } = this.state;

    if (data.laiGtfs != '') {
      return (
        <div className="data green half-length">
          <div className="data-header">
            {this.props.translations.mobility.lai_gtfs_title}
          </div>

          <div className="data-info">
            <div className="data-info-item">
              <div className="data-info-item-value">
                {data.laiGtfs}
              </div>

              <div className="data-info-item-label">

              </div>
            </div>
          </div>

          <div className="data-source">
            {this.renderSource(data.laiGtfsFonte)}
          </div>
        </div>
      );
    }
  }

  renderPlanMob(){
    const { data } = this.state;

    if (data.planmobAnoAprovacao != '') {
      return (
        <div className="data green half-length">
          <div className="data-header">
            {this.props.translations.mobility.plan_mob_title}
          </div>
          <div className="data-info">
            <div className="data-info-item">
              <div className="data-info-item-value">
                {data.planmobAnoAprovacao}
              </div>

              <div className="data-info-item-label">
                {this.props.translations.mobility.plan_mob_year}
              </div>
            </div>

            <div className="data-info-item">
              <div className="data-info-item-value">
                {data.planmobStatus}
              </div>

              <div className="data-info-item-label">
                {this.props.translations.mobility.plan_mob_status}
              </div>
            </div>
          </div>

          <div className="data-source">
            {this.renderSource(data.planmobFonte)}
          </div>
        </div>
      );
    }
  }

  renderTMAItem(valueStation, valueKm, unit, label) {
    if (valueStation !== undefined && valueStation.trim() !== '' && valueStation !== '0' && valueStation !== '0.0' ) {
      return (
        <div className="data-info-item">
          <div className="data-info-item-value">
            {valueStation} ({valueKm} {unit})
          </div>

          <div className="data-info-item-label">
            {label}
          </div>
        </div>
      );
    }
  }

  renderTMA() {
    const { data } = this.state;

    if ((data.tmaEstacaoBarca && data.tmaEstacaoBarca.trim() != '') &&
        (data.tmaEstacaoBrt && data.tmaEstacaoBrt.trim() != '') &&
        (data.tmaEstacaoMetro && data.tmaEstacaoMetro.trim() != '') &&
        (data.tmaEstacaoMonotrilho && data.tmaEstacaoMonotrilho.trim() != '') &&
        (data.tmaEstacaoVlt && data.tmaEstacaoVlt.trim() != '') &&
        (data.tmaEstacaoTrem && data.tmaEstacaoTrem.trim() != '')) {
      return (
        <div className="data blue">
          <div className="data-header">
            {this.props.translations.mobility.tma_title}
          </div>
          <div className="data-info">
            {this.renderTMAItem(data.tmaEstacaoBarca, data.tmaKmBarca, this.props.translations.mobility.tma_barca_unit, this.props.translations.mobility.tma_barca_label)}

            {this.renderTMAItem(data.tmaEstacaoBrt, data.tmaKmBrt, this.props.translations.mobility.tma_brt_unit, this.props.translations.mobility.tma_brt_label)}
            {this.renderTMAItem(data.tmaEstacaoMetro, data.tmaKmMetro, this.props.translations.mobility.tma_metro_unit, this.props.translations.mobility.tma_metro_label)}
            {this.renderTMAItem(data.tmaEstacaoMonotrilho, data.tmaKmMonotrilho, this.props.translations.mobility.tma_monotrilho_unit, this.props.translations.mobility.tma_monotrilho_label)}
            {this.renderTMAItem(data.tmaEstacaoTrem, data.tmaKmTrem, this.props.translations.mobility.tma_trem_unit, this.props.translations.mobility.tma_trem_label)}
            {this.renderTMAItem(data.tmaEstacaoVlt, data.tmaKmVlt, this.props.translations.mobility.tma_vlt_unit, this.props.translations.mobility.tma_vlt_label)}

          </div>
        </div>
      );
    }
  }

  renderDataThreeColumn(value, label, fonte) {
    return (
      <div className="data green third-length">
        <div className="data-header">
          {label}
        </div>

        <div className="data-info">
          <div className="data-info-item">
            <div className="data-info-item-value">
              {value}
            </div>
          </div>
        </div>

        <div className="data-source">
          {this.renderSource(fonte)}
        </div>
      </div>
    )
  }

  renderDataFourColumn(value, label, fonte) {
    return (
      <div className="data green quarter-length">
        <div className="data-header">
          {label}
        </div>

        <div className="data-info">
          <div className="data-info-item">
            <div className="data-info-item-value">
              {value}
            </div>
          </div>
        </div>

        <div className="data-source">
          {this.renderSource(fonte)}
        </div>
      </div>
    )
  }

  renderMobility() {
    const { data } = this.state;

    return (
      <div className="mobilidados-platform-capital-info-tab__content">
        {this.renderLAITransportContract()}
        {this.renderLAIGPS()}
        {this.renderLAIGTFS()}
        {this.renderPlanMob()}
        {this.renderTMA()}
      </div>
    )
  }

  renderElectricBus() {
    const { data } = this.state;
    const percElet = isNaN(parseFloat(data.percElet)) ? data.percElet : `${parseFloat(data.percElet)}${this.props.translations.info.perc_negros_unit}`

    return (
      <React.Fragment>
        <div className="mobilidados-platform-capital-info-tab__content">
          {this.renderDataThreeColumn(data.totOniElet, this.props.translations.electric_bus.tot_oni_elet, data.totOniEletFonte)}
          {this.renderDataThreeColumn(percElet, this.props.translations.electric_bus.perc_elet, data.percEletFonte)}
          {this.renderDataThreeColumn(data.emisEvit, this.props.translations.electric_bus.emis_evit, data.emisEvitFonte)}
        </div>
        <div className="mobilidados-platform-capital-info-tab__content">
          {this.renderDataFourColumn(data.oeEstudo, this.props.translations.electric_bus.oe_estudo, data.oeEstudoFonte)}
          {this.renderDataFourColumn(data.oePiloto, this.props.translations.electric_bus.oe_piloto, data.oePilotoFonte)}
          {this.renderDataFourColumn(data.oeCompromisso, this.props.translations.electric_bus.oe_compromisso, data.oeCompromissoFonte)}
          {this.renderDataFourColumn(data.oeOperacao, this.props.translations.electric_bus.oe_operacao, data.oeOperacaoFonte)}
        </div>
      </React.Fragment>
    )
  }

  renderTab() {
    if(this.state.tab == 'info') {
      return this.renderInfo();
    } else if(this.state.tab == 'mobility'){
      return this.renderMobility();
    } else {
      return this.renderElectricBus();
    }
  }

  renderTabOptions() {
    return ['info', 'mobility', 'electric_bus'].map((tab) => {
      return (
        <div
          className={this.state.tab == tab ? 'selected' : ''}
          onClick={() => { this.setState({ tab }); }}>
          { this.props.translations.tab[tab] }
        </div>
      )
    })
  }

  renderContent() {
    const { data } = this.state;

    if(!data) { return <Loading /> }

    return (
      <React.Fragment>
        <div className="mobilidados-platform-capital-info__title">
          {data.name}
        </div>

        <div className="mobilidados-platform-capital-info__description">
          {data.text}
        </div>

        <div className="mobilidados-platform-capital-info-tab">
          <div className="mobilidados-platform-capital-info-tab__options">
            {this.renderTabOptions()}
          </div>

          {this.renderTab()}
        </div>
      </React.Fragment>
    )
  }

  render() {
    const { onClose } = this.props;

    return (
      <ModalBox className="mobilidados-platform-capital-info">
        <a
          onClick={onClose.bind(this)}
          className='mobilidados-platform-capital-info__close-button'>
          <i className='material-icons'>close</i>
        </a>

        {this.renderContent()}
      </ModalBox>
    )
  }
}

export default TownCard;
