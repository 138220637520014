document.addEventListener("turbolinks:load", () => {
  const mainMenu = document.querySelector('#main-menu');

  if(mainMenu) {
    const toggler = mainMenu.querySelector('.mobilidados-menu__toggler');

    if (toggler.classList.contains('armed')) return;

    const toggleMenu = (e) => {
      e.stopPropagation();

      document.body.classList.toggle("mobilidados--menu-open");
      mainMenu.classList.toggle("mobilidados-menu__container--open");
    };

    toggler.removeEventListener('click', toggleMenu, true );
    toggler.addEventListener('click', toggleMenu);

    window.addEventListener('resize', (e) => {
      document.body.classList.remove("mobilidados--menu-open");
      mainMenu.classList.remove("mobilidados-menu__container--open");
    });
    toggler.classList.toggle('armed')

    // document.addEventListener('click', (e) => {
    //   if(mainMenu.classList.contains("mobilidados-menu__container--open")) {
    //     e.preventDefault();
    //     toggleMenu();
    //   }
    // })

  }
})
