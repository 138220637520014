import React from 'react';
import Select, { components } from 'react-select';

const themeColors = {
  primary: 'rgba(255,255,255,.8)',
  primary75: 'rgba(255,255,255,.75)',
  primary50: 'rgba(255,255,255,.5)',
  primary25: 'rgba(255,255,255,.25)',

  danger: '#DE350B',
  dangerLight: '#FFBDAD',

  neutral0: 'hsl(0, 0%, 10%)',
  neutral5: 'hsl(0, 0%, 20%)',
  neutral10: 'hsl(0, 0%, 30%)',
  neutral20: 'hsl(0, 0%, 40%)',
  neutral30: 'hsl(0, 0%, 50%)',
  neutral40: 'hsl(0, 0%, 60%)',
  neutral50: 'hsl(0, 0%, 70%)',
  neutral60: 'hsl(0, 0%, 80%)',
  neutral70: 'hsl(0, 0%, 90%)',
  neutral80: 'hsl(0, 0%, 95%)',
  neutral90: 'hsl(0, 0%, 100%)',
};

const theme = (theme) => {
  return {
    ...theme,
    borderRadius: 0,
    colors: {
      ...theme.colors,
      ...themeColors
    },
  }
}

const SelectField = (props) => {
  return (
    <Select
      placeholder={props['placeholder'] || 'Selecionar...'}
      theme={theme}
      {...props}
    />
  );
}

export default SelectField;
