import React, { Component } from 'react';
import PropTypes from 'prop-types';

class ModalBox extends Component{
  state = {
    contentShow: this.props.startOpen
  }

  renderClose() {
    if(this.props.onClose) {
      return (
        <a
        onClick={this.props.onClose.bind(this)}
        className='modal__box_close'>
        <i className='material-icons'>close</i>
        </a>
      );
    }
  }

  toggleHide() {
    this.setState({
      contentShow: !this.state.contentShow
    });
  }

  renderToggle() {
    if(this.props.togglable) {
      return (
        <a onClick={this.toggleHide.bind(this)}>
          <i className='material-icons'>
            {this.state.contentShow ? 'keyboard_arrow_down' : 'keyboard_arrow_right'}
          </i>
        </a>
      )
    }
  }

  renderContent() {
    return (
      <div className='mobilidados-platform-modal-box__content'>
        {this.props.children}
      </div>
    );
  }

  renderHeader() {
    if(this.props.label) {
      return (
        <div className='mobilidados-platform-modal-box__header'>
          {this.renderClose()}
          {this.renderToggle()}
          {this.props.label}
        </div>
      );
    }
  }

  hideClass() {
    return this.state.contentShow ? 'mobilidados-platform-modal-box--show' : 'mobilidados-platform-modal-box--hide';
  }


  render() {
    return (
      <div className={`mobilidados-platform-modal-box ${this.hideClass()} ${this.props.className}`} >
        {this.renderHeader()}
        {this.renderContent()}
      </div>
    );
  }
}

ModalBox.propTypes = {
  onClose: PropTypes.func,
  togglable: PropTypes.bool,
  startOpen: PropTypes.bool,
  className: PropTypes.string,
  label: PropTypes.string
}

ModalBox.defaultProps = {
  startOpen: true
}


export default ModalBox;
