document.addEventListener("turbolinks:load", () => {
  if(!!window.IntersectionObserver) {
    let options = { rootMargin: '-200px 0px 0px 0px'}
    const subnavDOM = document.querySelector('#subnav');
    const observerTarget =document.querySelector('.mobilidados-platform-navbar')
    if(subnavDOM && observerTarget) {
      let clonned = false;

      let callback = (intersectionEntries) => {
        intersectionEntries.forEach((entry) => {
          const target = entry.target;
          const scroll = parseInt(target.dataset.scroll);
          const threshold = target.offsetTop - 120;

          if(subnavDOM.innerHTML != '') {
            subnavDOM.innerHTML = '';
          }

          if(threshold < scroll) {
            if(!clonned && entry.intersectionRatio <= 0){
              subnavDOM.append(entry.target.cloneNode(true))
              clonned = true;
            } else {
              clonned = false;
            }
          } else {
            clonned = false;
          }
        })
      }

      let observer = new IntersectionObserver(callback, options);

      observer.observe(observerTarget)
    }
  }
});
