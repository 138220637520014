import React, { Fragment } from 'react';

import SelectField from '../SelectField';
import Loading from '../Loading';

import { useQuery } from '@apollo/client';

const Filter = ({ onChange, variables, filters, query, queryName }) => {
  const { loading, error, data } = useQuery(query, { variables });

  if (loading && !data) return (<Loading/>);

  const options = data[queryName];
  const labels = options['labels']

  return Object.keys(options).filter((key)=> key !== 'labels').map((filterName) => {
    return [
      <label>{labels[filterName]}</label>,
      <SelectField
        className='mobilidados-platform__select'
        value={filters[filterName]}
        options={options[filterName]}
        onChange={onChange.bind(this, filterName)}
      />
    ];
  });
}

export default Filter;
