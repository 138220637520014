import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Sidebar extends Component{
  state = {
    contentShow: true
  }

  toggleHide() {
    const { contentShow } = this.state;
    this.setState({
      contentShow: !contentShow
    });
  }

  renderToggle() {
    const { contentShow } = this.state;
    if(this.props.togglable) {
      return (
        <a className="Sidebar__toggler" onClick={this.toggleHide.bind(this)}>
          <i className='material-icons'>
            {contentShow ? 'keyboard_arrow_left' : 'keyboard_arrow_right'}
          </i>
        </a>
      )
    }
  }

  renderContent() {
    if(this.state.contentShow) {
      return (
        <div className='Sidebar__content'>
          {this.props.children}
        </div>
      );
    }
  }

  hideClass() {
    return this.state.contentShow ? 'Sidebar--show' : 'Sidebar--hide';
  }

  render() {
    return (
      <div className={`Sidebar ${this.hideClass()} ${this.props.className}`} >
        {this.renderToggle()}
        {this.renderContent()}
      </div>
    );
  }
}

Map.propTypes = {
  onClose: PropTypes.func,
  togglable: PropTypes.bool,
  className: PropTypes.string
}

Map.defaultProps = {}


export default Sidebar;
