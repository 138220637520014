import { gql } from '@apollo/client';

const HEADER_MAP_LAYERS = gql`
  query metropolitanRegionHeaderMap($abbrev: String!) {
    metropolitanRegionHeaderMap(abbrev: $abbrev) {
      dataLayers {
        name
        source
        layer
        legend
        allowedKeys
      }

      styledLayers {
        name
        source
        layer
        legend
      }

      center
      zoom
    }

    metropolitanRegion(abbrev: $abbrev) {
      name
      text
      area
      idhm
      population
      density
      percDrLess1sm
      percNegros
      percMulheres
    }
  }
`;

export {
  HEADER_MAP_LAYERS
}
