import React from 'react';
import Chart from '../../../ui/Chart';

import { numberFormat } from '../../../libs/number_format';

const ModalSplitChart = ({ data }) => {
  const localTranslations = {
    'directions_walk': "A pé",
    'directions_bike': "Bicicleta",
    'directions_bus': "Transporte coletivo",
    'directions_car': "Transp. individual motorizado"
  };

  const settings = {
    title: data['datasets'][0]['label'],
    type: 'horizontalBar',
    data: data,
    options: {
      tooltips: {
        titleFontSize: 0,
        callbacks: {
          label: (opt, { datasets, labels }) => {
            const value = datasets[opt.datasetIndex]['data'][opt.index];
            const label = labels[opt.index];

            return ` ${localTranslations[label]}: ${numberFormat(value)}%`;
          }
        }
      },
      scales: {
        yAxes: [{
          scaleLabel: {
            display: true,
            fontColor: 'rgba(255, 255, 255)'
          },
          ticks: {
            fontColor: 'rgba(255, 255, 255)',
            fontFamily: 'Material Icons',
            fontSize: 18
          }
        }],
        xAxes: [{
          scaleLabel: {
            display: true,
            fontColor: 'rgba(255, 255, 255)'
          },
          ticks: {
            fontColor: 'rgba(255, 255, 255)',
            beginAtZero: true,
            suggestedMin: 0,
            suggestedMax: 100
          }
        }]
      },
      legend: {
        display: false
      }
    }
  }

  return <Chart settings={settings} />
}

export default ModalSplitChart;
