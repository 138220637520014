document.addEventListener("turbolinks:load", () => {

  document.addEventListener('click', (event)=>{
    const targetSelector = event.target.getAttribute('scroll-to');
    if(targetSelector) {
      const target = document.querySelector(targetSelector);

      window.scroll({
        top: target.offsetTop - 100,
        behavior: 'smooth'
      });
    }
  });

  const updateScroll = () => {
    document.querySelectorAll('[data-scroll]').forEach((elDOM) => {
      elDOM.dataset.scroll = window.scrollY;
    })
  }

  document.addEventListener('scroll', updateScroll, { passive: true });
  updateScroll();
})
