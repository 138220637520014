import React from 'react';
import ModalBox from '../../../ui/ModalBox';
import { numberFormat } from '../../../libs/number_format';

const MetropolitanRegionCard = ({ data })=>{
  return (
    <ModalBox label={data.name} className="HeaderBox text-white">
      <div>{data.text}</div>

      <div className="mobilidados-content mobilidados-content--wrap text-white">
        <div className="half-length">
          <strong>Área</strong>
          <br />
          {data.area}
          <small>Km²</small>
        </div>

        <div className="half-length">
          <strong>IDHM</strong>
          <br />
          {data.idhm}
          <small></small>
        </div>

        <div className="half-length">
          <strong>População</strong>
          <br />
          {numberFormat(data.population)}
        </div>

        <div className="half-length">
          <strong>Densidade Urbana</strong>
          <br />
          {data.density.toFixed(3)}
          <small>hab/Km²</small>
        </div>

        <div className="full-length mobilidados-content">
          <div className="half-length">
            <strong>Domicílios com renda abaixo de um salário mínimo per capita</strong>
          </div>

          <div>
            {data.percDrLess1sm} %
          </div>
        </div>

        <div className="full-length mobilidados-content">
          <div className="half-length">
            <strong>População negra</strong>
          </div>

          <div>
            {data.percNegros} %
          </div>
        </div>

        <div className="full-length mobilidados-content">
          <div className="half-length">
            <strong>Percentual de mulheres na população</strong>
          </div>

          <div>
            {data.percMulheres} %
          </div>
        </div>
      </div>
    </ModalBox>
  )
}

export default MetropolitanRegionCard;
