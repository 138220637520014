import { gql } from '@apollo/client';

const INFRASTRUCTURE_MAP = gql`
  query metropolitanRegionInfrastructureMap($abbrev: String!, $indicatorId: Int) {
    metropolitanRegionInfrastructureMap(abbrev: $abbrev, indicatorId: $indicatorId) {
      dataLayers {
        name
        source
        layer
        legend
        allowedKeys
      }

      styledLayers {
        name
        source
        layer
        legend
      }

      center
      zoom
    }
  }
`;

const INFRASTRUCTURE_CHART = gql`
  query metropolitanRegionInfrastructureChart($abbrev: String!, $indicatorId: Int!) {
    metropolitanRegionInfrastructureChart(abbrev: $abbrev, indicatorId: $indicatorId) {
      labels
      datasets
    }
  }
`;

const INFRASTRUCTURE_FILTERS = gql`
  query metropolitanRegionInfrastructureFilters($abbrev: String!) {
    metropolitanRegionInfrastructureFilters(abbrev: $abbrev)
  }
`;

const INFRASTRUCTURE_INDICATOR = gql`
  query metropolitanRegionInfrastructureIndicator($indicatorId: Int!) {
    indicatorGroup(id: $indicatorId)
  }
`;

export {
  INFRASTRUCTURE_MAP,
  INFRASTRUCTURE_CHART,
  INFRASTRUCTURE_FILTERS,
  INFRASTRUCTURE_INDICATOR
}
