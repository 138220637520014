import 'channels'
import './libs';
import './sections';
import { start as ujsStart } from '@rails/ujs';
import { start as activestorageStart } from '@rails/activestorage';

ujsStart();

document.addEventListener("turbolinks:load", () => {
  activestorageStart();
});
