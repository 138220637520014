import React from 'react';
import { components } from 'react-select';

const ValueContainer = (props) => {
  if(!props.isMulti) { return <components.ValueContainer {...props} /> }

  const length = (props.getValue() || []).length;
  const message = () => {
    if(length === 0){
      return 'Selecione';
    }

    if(length == props.options.length) {
      return 'Todos selecionados';
    } else {
      return `${length} selecionados`;
    }
  }

  return (
    <div className="RemoteSelect-ValueContainer">
      {message()}
    </div>
  );
};


const Option = (props) => {
  return (
    <components.Option {...props}>
      <span>
        {props.isSelected && (<i className="fa fa-check" />)}
        &nbsp;
        {props.children}
      </span>
    </components.Option>
  );
};

export {
  ValueContainer,
  Option
};
