import React from 'react';

import ModalBox from '../ModalBox';
import { numberFormat } from '../../libs/number_format';

const MetadataPopup = ({ data }) => {
  const formatText = (value) => {
    if(!isNaN(parseFloat(value)) && !Number.isInteger(parseFloat(value))) {
      return numberFormat(parseFloat(value));
    }

    return value;
  }
  return (
    <ModalBox label='Dados' togglable={true}>
      <table>
        <tbody>
          {data.map(({ label, value, unit }, index) => {
            return (
              <tr key={`metadata_${index}`}>
                <td><strong>{label}</strong></td>
                <td
                  className="MetadataPopupContentData"
                  dangerouslySetInnerHTML={{__html: `${formatText(value)} ${unit}`}}
                />
              </tr>
            );
          })}
        </tbody>
      </table>
    </ModalBox>
  );
}

export default MetadataPopup;
