import React from 'react';
import ModalSplitChart from './ModalSplitChart';
import Loading from '../../../ui/Loading';

import { useQuery } from '@apollo/client';
import { numberFormat } from '../../../libs/number_format';

import { MODAL_SPLIT_CHART } from './queries';

const ModalSplit = ({ mr, translations }) => {
  const { loading, error, data } = useQuery(MODAL_SPLIT_CHART, {
    variables: { abbrev: mr },
  });

  if (loading) return (<Loading/>);
  const charts = data['metropolitanRegionModalSplitChart']
  if(charts.length === 0 ){
    return (
      <div className="mobilidados-platform-metropolitan-region__modal-division-item">
        {translations.no_data}
      </div>
    )
  }
  return charts.map((data) => {
    return (
      <div className="mobilidados-platform-metropolitan-region__modal-division-item">
        <ModalSplitChart data={data} />
      </div>
    )
  })
}

export default ModalSplit;
