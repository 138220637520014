import { gql } from '@apollo/client';

const POPULATION_CHART = gql`
  query metropolitanRegionPopulationChart($abbrev: String!) {
    metropolitanRegionPopulationChart(abbrev: $abbrev) {
      labels
      datasets
    }
  }
`;

export { POPULATION_CHART };
