import { gql } from '@apollo/client';

const DENSITY_CHART = gql`
  query metropolitanRegionDensityChart($abbrev: String!) {
    metropolitanRegionDensityChart(abbrev: $abbrev) {
      labels
      datasets
    }
  }
`;

export { DENSITY_CHART };
