import React, { Component } from 'react';
import Map from '../../../ui/Map';
import Loading from '../../../ui/Loading';
import { client } from  '../../../configs/apollo';
import { HEADER_MAP_LAYERS } from './queries';
import MetropolitanRegionCard from './MetropolitanRegionCard';

class Header extends Component{
  state = {
    loading: true,
    metropolitanRegion: {},
    config: {
      dataLayers: [],
      styledLayers: []
    },
  }

  fetchLayers() {
    client.query({
      query: HEADER_MAP_LAYERS,
      variables: { abbrev: this.props.mr }
    }).then(({ data: { metropolitanRegionHeaderMap, metropolitanRegion } }) => {
      const {
        dataLayers,
        styledLayers,
        center,
        zoom
      } = metropolitanRegionHeaderMap;

      this.setState({
        loading: false,
        metropolitanRegion,
        config: {
          dataLayers,
          styledLayers,
          center,
          zoom
        }
      })
    });
  }

  componentDidMount() {
    this.fetchLayers();
  }

  render() {
    const { config, loading } = this.state;

    if(loading) return (<Loading/>);

    return (
      <Map {...config} interactive={false}>
        <MetropolitanRegionCard data={this.state.metropolitanRegion} />
      </Map>
    );
  }
}

export default Header;
