import React from 'react';
import Tooltip from 'react-tooltip';

import ModalBox from '../../../ui/ModalBox';
import LegendStep from './LegendStep';

const Legend = ({ items, children }) => {
  return (
    <div className='Legend'>
      <Tooltip />

      <ModalBox
        label='Legenda'
        className='Legend-container'
        togglable={true}
        startOpen={false}
      >
        <div className='Legend-content'>
          {items.map((step, i) => (
            <LegendStep {...step} key={`step_${i}`}/>
          ))}
        </div>

        {children}
      </ModalBox>
    </div>
  )
}

export default Legend;
