import { gql } from '@apollo/client';

const TOWN_HEADER_MAP = gql`
  query {
    townHeaderMap {
      dataLayers {
        name
        source
        layer
        legend
        allowedKeys
      }

      styledLayers {
        name
        source
        layer
        legend
      }

      center
      zoom
    }
  }
`;


const TOWN_HEADER_DATA = gql`
  query townHeader($id: Int!) {
    town(id: $id) {
      area
      capital
      urbanDensity
      id
      idhm
      idhmRange
      income
      laiContrato
      laiContratoFonte
      laiContratoInicio
      laiContratoPrazo
      laiGps
      laiGpsFrota
      laiGtfs
      laiGtfsFonte
      laiGpsFonte
      name
      percBrancos
      percDrLess1sm
      percHomens
      percMulheres
      percNegros
      planmobAnoAprovacao
      planmobFonte
      planmobStatus
      population
      slug
      text
      tmaEstacaoBarca
      tmaEstacaoBrt
      tmaEstacaoMetro
      tmaEstacaoMonotrilho
      tmaEstacaoTrem
      tmaEstacaoVlt
      tmaKmBarca
      tmaKmBrt
      tmaKmMetro
      tmaKmMonotrilho
      tmaKmTrem
      tmaKmVlt
      townCode
      urbanArea
      urbanDensity
      totOniElet
      percElet
      emisEvit
      oeEstudo
      oePiloto
      oeCompromisso
      oeOperacao
      totOniEletFonte
      percEletFonte
      emisEvitFonte
      oeEstudoFonte
      oePilotoFonte
      oeCompromissoFonte
      oeOperacaoFonte
    }
  }
`
export {
  TOWN_HEADER_MAP,
  TOWN_HEADER_DATA
}
