import React, { Component } from 'react';
import Map from '../Map';
import Sidebar from '../Sidebar';

import Filter from './Filter';
import IndicatorChart from './IndicatorChart';
import IndicatorDescription from './IndicatorDescription';

import { client } from  '../../configs/apollo';

class InfrastructureMap extends Component{
  state = {
    config: {
      dataLayers: [],
      styledLayers: []
    },
    filters: {
      ...this.props.defaultFilters,
      indicator: null
    }
  }

  get variables() {
    const { filters } = this.state;

    const filteredVariables = Object.keys(filters).reduce((variables, key) => {
      const filter = filters[key];

      const value = filter && parseInt(filter['value'])

      if(!value) {
        return variables;
      }

      return {
        ...variables,
        [`${key}Id`]: value
      }
    }, {});

    return {
      ...this.props.defaultVariables,
      ...filteredVariables
    }
  }

  handleFilter(field, value) {
    this.setState({
      filters: {
        ...this.state.filters,
        [field]: value
      }
    });
  }

  async fetchLayers() {
    client.query({
      query: this.props.queries.map,
      variables: this.variables
    }).then(({ data }) => {
      const config = data[`${this.props.queryPrefix}Map`];

      if(!config) { return; }

      this.setState({ config });
    });
  }

  componentDidUpdate(_prevProps, prevState) {
    if(JSON.stringify(prevState.filters) !== JSON.stringify(this.state.filters)) {
      this.fetchLayers();
    }
  }

  renderIndicatorChart() {
    if(this.variables.indicatorId) {
      return (
        <IndicatorChart
          variables={this.variables}
          query={this.props.queries.chart}
          queryName={`${this.props.queryPrefix}Chart`} />
      );
    }
  }

  renderIndicatorInfo() {
    if(this.variables.indicatorId) {
      return (
        <IndicatorDescription
          variables={this.variables}
          query={this.props.queries.indicator}
          queryName={`${this.props.queryPrefix}Description`} />
      );
    }
  }

  renderFilters() {
    return (
      <Filter
        variables={this.variables}
        filters={this.state.filters}
        onChange={this.handleFilter.bind(this)}
        query={this.props.queries.filters}
        queryName={`${this.props.queryPrefix}Filters`}
      />
    );
  }

  render() {
    const { config, filters } = this.state;

    return (
      <Map {...config} mapSwitcher={true}>
        <Sidebar togglable={true}>
          {this.renderFilters()}
          {this.renderIndicatorInfo()}
          {this.renderIndicatorChart()}
        </Sidebar>
      </Map>
    );
  }
}

export default InfrastructureMap;
