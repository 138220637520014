import React, { PureComponent } from 'react';
import Chart from 'chart.js';

import {addController} from './Dumbbell';

addController();

class ChartComponent extends PureComponent {

  renderChart() {
    this.chart = new Chart(this.refs.canvas, this.props.settings)
  }

  componentDidMount() {
    this.renderChart();
  }

  componentDidUpdate() {
    this.chart.destroy();
    this.renderChart();
  }

  render() {
    return (
      <div className={`mobilidados-chart ${this.props.className}`}>
        {this.props.settings.title && (
          <div className="mobilidados-chart__title">
            {this.props.settings.title}
          </div>
        )}
        <canvas
          ref="canvas" className={`mobilidados-chart__canvas`}
          {...(this.props.chartOptions || {})}
        />
      </div>
    )
  }
}

export default ChartComponent;
