import React, { Component } from 'react';
import SelectField from '../../ui/SelectField';
import Loading from '../../ui/Loading';
import { client } from  '../../configs/apollo';
import * as customComponents from './CustomComponents';

class RemoteSelect extends Component {
  state = {
    loading: false,
    remoteOptions: [],
    options: []
  }

  get options() {
    return this.state.options;
  }

  fetchData() {
    const { variables, query, queryName, values } = this.props;

    if(variables != null && Object.keys(variables).length === 0) { return; }

    this.setState({ loading: true, remoteOptions: [] });

    client.query({
      query,
      variables
    }).then(({ data }) => {
      this.setState({
        loading: false,
        remoteOptions: data[queryName],
        options: this.prepareOptions(data[queryName])
      });
    });
  }

  prepareOptions(options) {
    const { values, onChange } = this.props;

    if(this.props.selectAll) {
      options = [
        {
          value: 'select_all',
          label: 'Selecionar todos'
        },
        ...options
      ];
    }

    return options;
  }

  selectAll() {
    if(this.state.remoteOptions.length) {
      this.props.onChange(this.state.remoteOptions);
    }
  }

  handleChange(selectedValues) {
    if(this.props.selectAll) {
      if(!!selectedValues.find(({ value }) => value === 'select_all' )) {
        return this.selectAll();
      }
    }

    this.props.onChange(selectedValues);
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate({ variables }, { loading, remoteOptions }) {
    if(JSON.stringify(variables) !== JSON.stringify(this.props.variables)) {
      this.fetchData();
    }

    if(JSON.stringify(remoteOptions) !== JSON.stringify(this.state.remoteOptions)) {
      this.props.selectAll && this.selectAll();
    }

    if(loading === false && this.state.loading === true) {
      this.props.onLoad && this.props.onLoad();
    }
  }

  render() {
    return (
      <SelectField
        hideSelectedOptions={false}
        value={this.props.values}
        options={this.options}
        closeMenuOnSelect={!this.props.isMulti}
        isMulti={this.props.isMulti}
        components={{ ...customComponents }}
        onChange={this.handleChange.bind(this)}
      />
    );
  }
}

export default RemoteSelect;
