import React, { Component } from 'react';
import HistoricalSeries from '../../../ui/HistoricalSeries';

import { INDICATORS, YEARS, LOCATIONS, CHART } from './queries';

const HistoricalSeriesContainer = ({mr}) => {
  const options = {
    query: {
      chart: CHART,
      indicators: INDICATORS,
      years: YEARS,
      locations: LOCATIONS
    },
    queryPrefix: 'metropolitanRegionHistoricalSeries',
    defaultVariables: { abbrev: mr },
    locationLabel: 'Região metropolitana'
  }

  return <HistoricalSeries {...options} />
}

export default HistoricalSeriesContainer;
