import React, { Component } from 'react';
import { gql } from '@apollo/client';
import { client } from  '../../../configs/apollo';

class MapStyleSwitcher extends Component {
  state = {
    baseMaps: []
  }

  componentDidMount() {
    client.query({
      query: gql`{ baseMaps { id, name, key, thumbUrl } }`
    }).then(({ data }) => {
      const baseMaps = data['baseMaps'];

      if(!baseMaps) { return; }

      this.setState({ baseMaps });
    });
  }

  renderSwitchers(){
    const { baseMap, onChangeBaseMap } = this.props;

    return this.state.baseMaps.map(({ key, thumbUrl })=>{
      return (
        <div key={key}
          onClick={onChangeBaseMap.bind(null, key)}
          className={key === baseMap ? 'selected' : null}
        >
          <img src={thumbUrl} />
        </div>
      );
    });
  }

  render() {
    return (
      <div className='BaseMapSwitcher'>
        {this.renderSwitchers()}
      </div>
    );
  }
}


export default MapStyleSwitcher;
