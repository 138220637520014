import React from 'react';
import Chart from '../Chart';
import Loading from '../Loading';

import { useQuery } from '@apollo/client';
import { numberFormat } from '../../libs/number_format';

const IndicatorChart = ({ variables, query, queryName }) => {
  const { loading, error, data } = useQuery(query, { variables });

  if (loading || !data ) return (<Loading/>);

  const settings = {
    title: null,
    showLine: false,
    type: 'dumbbell',
    data: data[queryName],
    options: {
      devicePixelRatio: 1,
      responsive: true,
      legend: {
        position: 'bottom',
        labels: {
          boxWidth: 10,
          fontSize: 10,
          filter: (item) => {
            return !data[queryName]['labels'].includes(item.text);
          },
        },
      },
      tooltips: {
        titleFontSize: 10,
        filter: (element, { datasets }) => {
          const dataset = datasets[element.datasetIndex];
          return !data[queryName]['labels'].includes(dataset.label);
        },
        callbacks: {
          title() {
            return '';
          },
          label(opt, { datasets }){
            const dataset = datasets[opt.datasetIndex];
            return `${dataset.label}: ${numberFormat(opt.xLabel)} %`;
          }
        }
      },
    }
  };

  return (
    <Chart
      settings={settings}
      className={"mobilidados-platform__indicator-chart"}
      chartOptions={{height: (window.devicePixelRatio == 1 || window.outerHeight > 640 ? '400' : '350' )}}
    />
  );
}

export default IndicatorChart;
