import React,  { PureComponent } from 'react';

import MetadataPopup from '../../MetadataPopup';

class MetadataContainer extends PureComponent {
  formatedData() {
    const { allowedKeys, features } = this.props;

    const properties = features.reduce((data, feature) => {
      return {
        ...data,
        ...feature['properties']
      };
    }, {});

    this.hasNulls = false;

    return allowedKeys.map(({ label, key, unit }) => {
      let formatedValue = properties[key];

      if(typeof formatedValue === 'number') {
        formatedValue = formatedValue.toFixed(0);
      }

      if(typeof formatedValue === 'undefined') {
        formatedValue = '-';
      }

      const value = `${formatedValue}`;

      if(!this.hasNulls && !value && value !== 0 ) {
        this.hasNulls = true;
      }
      return { label, value, unit };
    }, [])
  }

  render() {
    const data = this.formatedData();

    if(this.hasNulls) { return null; }

    return (
      <div className="MetadataContainer">
        <MetadataPopup data={data} />
      </div>
    )
  }
}

export default MetadataContainer;
