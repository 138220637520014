import React, { Component, Fragment } from 'react';
import Chart from '../../ui/Chart';
import Loading from '../../ui/Loading';

import { client } from  '../../configs/apollo';
import { numberFormat } from '../../libs/number_format';

class HistoryChart extends Component{
  state = {
    loading: false,
    chartData: null,
    indicator: null
  }

  get settings() {
    const { years } = this.props.variables;
    const {
      indicator: { name, unit },
      loading,
      chartData
    } = this.state;

    return {
      title: null,
      type: years && years.length > 1 ? 'line' : 'bar',
      data: chartData,
      options: {
        legend: {
          display: true,
          position: 'bottom',
          labels: {
            fontColor: 'rgba(255, 255, 255)',
            boxWidth: 10,
            fontSize: 10
          }
        },
        tooltips: {
          callbacks: {
            label: (tooltipItems, { datasets }) => {
              const label = datasets[tooltipItems.datasetIndex]['label'];
              return ` ${label}: ${numberFormat(tooltipItems.yLabel, '0.00')}`;
            }
          }
        },
        scales: {
          xAxes: [{
            gridLines: {
              display: true,
              color: 'rgba(255, 255, 255, 0.3)'
            },
            scaleLabel: {
              display: true,
              fontColor: 'rgba(255, 255, 255)'
            },
            ticks: {
              fontColor: 'rgba(255, 255, 255)',
            }
          }],
          yAxes: [{
            gridLines: {
              display: true,
              color: 'rgba(255, 255, 255, 0.3)'
            },
            scaleLabel: {
              display: true,
              labelString: unit,
              fontColor: '#fff',
              fontSize: 14
            },
            ticks: {
              fontColor: 'rgba(255, 255, 255)',
              beginAtZero: true,
              callback: (value, index, values) => {
                return numberFormat(value || 0);
              }
            }
          }]
        }
      }
    };
  }

  fetchData() {
    const { variables, query, queryName } = this.props;

    this.setState({ loading: true });

    client.query({
      query,
      variables
    }).then(({ data }) => {
      const [chartData, indicator] = data[queryName];

      this.setState({
        loading: false,
        chartData,
        indicator
      })
    });
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if(JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
      this.fetchData();
    }
  }

  render() {
    const { indicator, loading, chartData } = this.state;
    if (loading || !indicator || !chartData) return (<Loading/>);

    return  (
      <Fragment>
        <div className="mobilidados-platform-historical-series__chart-description">
          {indicator.description}
        </div>

        <Chart settings={this.settings} />

        <div className="mobilidados-platform-historical-series__chart-source">
          {indicator.source}
        </div>
      </Fragment>
    )
  }
}

export default HistoryChart;
