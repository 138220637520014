import React, { PureComponent } from 'react';

class LegendStep extends PureComponent {
  renderIcon() {
    const { type, color, fill } = this.props;

    if(type !== 'title') {
      return (
        <div className='Legend-item-icon'>
          <div className={`Legend-item-icon-${type}`} style={{
            backgroundColor: fill,
            borderColor: color
          }}>
          </div>
        </div>
      );
    }
  }

  render() {
    const { value, type, fullName } = this.props;

    const title = type === 'title' ? 'Legend-item-title' : '';

    return (
      <div className={`Legend-item ${title}` } data-tip={fullName}>
        {this.renderIcon()}

        <div className={'Legend-item-label'}>
          {value}
        </div>
      </div>
    );
  }
}

export default LegendStep;
