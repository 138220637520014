import React from 'react';

const Loading = (props) => {
  return (
    <div className='Loading'>
      <div></div>
    </div>
  )
}

export default Loading;
