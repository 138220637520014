import { gql } from '@apollo/client';

const INDICATORS = gql`
  query metropolitanRegionHistoricalSeriesIndicators($abbrev: String!) {
    metropolitanRegionHistoricalSeriesIndicators(abbrev: $abbrev) {
      label
      value
    }
  }
`;

const YEARS = gql`
  query metropolitanRegionHistoricalSeriesYears($abbrev: String!, $indicatorId: Int!) {
    metropolitanRegionHistoricalSeriesYears(abbrev: $abbrev, indicatorId: $indicatorId) {
      label
      value
    }
  }
`;

const LOCATIONS = gql`
  query metropolitanRegionHistoricalSeriesLocations($abbrev: String!) {
    metropolitanRegionHistoricalSeriesLocations(abbrev: $abbrev) {
      label
      value
    }
  }
`;

const CHART = gql`
  query metropolitanRegionHistoricalSeriesChart($abbrev: String!, $indicatorId: Int!, $ids: [Int!]!, $years: [Int!]! ) {
    metropolitanRegionHistoricalSeriesChart(abbrev: $abbrev, indicatorId: $indicatorId, ids: $ids, years: $years)
  }
`;

export {
  INDICATORS,
  YEARS,
  LOCATIONS,
  CHART
};
