import { gql } from '@apollo/client';

const MODAL_SPLIT_CHART = gql`
  query metropolitanRegionModalSplitChart($abbrev: String!) {
    metropolitanRegionModalSplitChart(abbrev: $abbrev) {
      labels
      datasets
    }
  }
`;

export { MODAL_SPLIT_CHART };
