import React from 'react';
import Chart from '../../../ui/Chart';
import Loading from '../../../ui/Loading';

import { useQuery } from '@apollo/client';
import { numberFormat } from '../../../libs/number_format';

import { POPULATION_CHART } from './queries';

const PopulationChart = ({ mr }) => {
  const { loading, data } = useQuery(POPULATION_CHART, {
    variables: { abbrev: mr },
  });

  if (loading) return (<Loading/>);

  const settings = {
    title: 'População',
    type: 'line',
    data: data['metropolitanRegionPopulationChart'],
    options: {
      plugins: {
          filler: {
              propagate: true
          }
      },
      legend: {
        display: false
      },
      tooltips: {
        callbacks: {
          label: (tooltipItems, data) => {
            return numberFormat(parseFloat(tooltipItems.yLabel));
          }
        }
      },
      scales: {
        xAxes: [{
          scaleLabel: {
            display: true,
            fontColor: 'rgba(255, 255, 255)'
          },
          ticks: {
            fontColor: 'rgba(255, 255, 255)',
          }
        }],
        yAxes: [{
          scaleLabel: {
            display: true,
            fontColor: 'rgba(255, 255, 255)'
          },
          ticks: {
            fontColor: 'rgba(255, 255, 255)',
            beginAtZero: true,
            callback: (value, index, values) => {
              return numberFormat(parseFloat(value));
            },
            color: 'white'
          }
        }]
      }
    }
  };

  return <Chart settings={settings} />
}

export default PopulationChart;
