import React, { Component } from 'react';
import Map from '../../../ui/Map';
import Loading from '../../../ui/Loading';
import TownCard from './TownCard';
import { client } from  '../../../configs/apollo';
import { TOWN_HEADER_MAP } from './queries';

class Header extends Component{
  state = {
    loading: true,
    config: {
      dataLayers: [],
      styledLayers: []
    },
    selectedCapitalId: null,
    capitalHover: null
  }

  mapInstance = null;

  fetchLayers() {
    client.query({
      query: TOWN_HEADER_MAP
    }).then(({ data: { townHeaderMap } }) => {
      const {
        dataLayers,
        styledLayers,
        center,
        zoom
      } = townHeaderMap;

      this.setState({
        loading: false,
        config: {
          dataLayers,
          styledLayers,
          center,
          zoom
        }
      })
    });
  }

  componentDidMount() {
    this.fetchLayers();
  }

  handleMouseOver({features}, _map, mapInstance) {
    const capitalName = features[0].properties.name;

    this.setState({ capitalName });
  }

  handleMouseLeave() {
    this.setState({ capitalName: null });
  }

  handleClick({features}, _map, mapInstance) {
    const selectedCapital = features[0];

    if(!this.mapInstance){
      this.mapInstance = mapInstance;
    }

    this.setState({ selectedCapital });
  }

  handleTownInfoClose() {
    this.mapInstance.clearHighlightFeature();

    this.setState({
      selectedCapital: null
    });
  }

  renderTownInfo() {
    if(this.state.selectedCapital) {
      return (
        <TownCard
          data={this.state.selectedCapital.properties}
          translations={this.props.translations}
          onClose={this.handleTownInfoClose.bind(this)}
        />
      );
    }
  }

  renderGuide() {
    if(!this.state.selectedCapital) {
      return (
        <div className='mobilidados-platform-capital__header-helper-text'>
          {this.state.capitalName ? this.state.capitalName : "Clique na capital para começar"}
        </div>
      );
    }
  }

  render() {
    const { config, loading } = this.state;

    if(loading) return (<Loading/>);

    return (
      <Map
        {...config}
        interactive={false}
        controls={true}
        onMouseOver={this.handleMouseOver.bind(this)}
        onMouseLeave={this.handleMouseLeave.bind(this)}
        onClick={this.handleClick.bind(this)}
        metadataRender={() => {}}
        >
        {this.renderTownInfo()}
        {this.renderGuide()}
      </Map>
    );
  }
}

export default Header;
