import React from 'react';
import { safeRender } from '../../render';

import PopulationChart from  './PopulationChart';
import DensityChart from  './DensityChart';
import ModalSplit from  './ModalSplit';
import InfrastructureMap from  './InfrastructureMap';
import HistoricalSeries from  './HistoricalSeries';
import Header from  './Header';

safeRender('#metropolitan-region-header', Header);
safeRender('#metropolitan-region-population', PopulationChart);
safeRender('#metropolitan-region-density', DensityChart);
safeRender('#metropolitan-region-modal-division', ModalSplit);
safeRender('#metropolitan-region-infrastructure-map', InfrastructureMap);
safeRender('#metropolitan-region-historical-series', HistoricalSeries);
