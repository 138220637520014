import React from 'react';
import Loading from '../Loading';

import { useQuery } from '@apollo/client';

const IndicatorDescription = ({ variables, query, queryName }) => {
  const { loading, error, data } = useQuery(query, { variables });

  if (loading || !data ) return (<Loading/>);

  const indicator = data['indicatorGroup'];

  return (
    <div className="mobilidados-platform__indicator">
      <div className="mobilidados-platform__indicator-title">
        {indicator.name}
      </div>

      <div className="mobilidados-platform__indicator-description">
        {indicator.description}
      </div>
    </div>
  );
}

export default IndicatorDescription;
