import Flickity from 'flickity';
import 'flickity/css/flickity.css';

document.addEventListener("turbolinks:load", () => {
  const carrouselList = document.querySelectorAll('.carrousel');

  if(carrouselList.length > 0) {
    carrouselList.forEach((carousel) => new Flickity(carousel));
  }
})
