import React, { Component } from 'react';
import { safeRender } from '../../render';
import Cookies from 'js-cookie'

const COOKIE_NAME = 'accept-cookies';
// Global site tag (gtag.js) - Google Analytics

const gaScripts = (code) => {
  return `
    <script async src="https://www.googletagmanager.com/gtag/js?id=${code}"></script>
    <script>
      window.dataLayer = window.dataLayer || [];
      function gtag() {dataLayer.push(arguments);}
      gtag('js', new Date());

      gtag('config', '${code}');
    </script>
  `;
}

class CookieAccept extends Component {
  state = {
    cookieStatus: Cookies.get(COOKIE_NAME) || null
  }

  storeAnswer(cookieStatus) {
    Cookies.set(COOKIE_NAME, cookieStatus);
    this.setState({ cookieStatus });
  }

  acceptCookies() {
    this.storeAnswer("true")
  }

  denyCookies() {
    this.storeAnswer("false")
  }

  renderGA(code) {
    return <div dangerouslySetInnerHTML={ {__html: gaScripts(code)}}/>
  }

  render() {
    const { code, translations } = this.props;

    if(this.state.cookieStatus === "true") { return this.renderGA(code); }
    if(this.state.cookieStatus === "false") { return null; }

    return (
      <div className="lgpd-cookie-policy">
        <div
          className="lgpd-cookie-policy__message"
          dangerouslySetInnerHTML={ {__html: translations.message}}
        ></div>

        <button
          className="lgpd-cookie-policy__accept"
          onClick={this.acceptCookies.bind(this)}>
          {translations.accept}
        </button>
        <button
          className="lgpd-cookie-policy__deny"
          onClick={this.denyCookies.bind(this)}>
          {translations.deny}
        </button>
      </div>
    );
  }
};

safeRender('#lgpd_cookies', CookieAccept);
