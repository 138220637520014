import { gql } from '@apollo/client';

const INDICATORS = gql`
  query townHistoricalSeriesIndicators {
    townHistoricalSeriesIndicators {
      label
      value
    }
  }
`;

const YEARS = gql`
  query townHistoricalSeriesYears($indicatorId: Int!) {
    townHistoricalSeriesYears(indicatorId: $indicatorId) {
      label
      value
    }
  }
`;

const LOCATIONS = gql`
  query townHistoricalSeriesLocations {
    townHistoricalSeriesLocations {
      label
      value
    }
  }
`;

const CHART = gql`
  query townHistoricalSeriesChart($indicatorId: Int!, $ids: [Int!]!, $years: [Int!]! ) {
    townHistoricalSeriesChart(indicatorId: $indicatorId, ids: $ids, years: $years)
  }
`;

export {
  INDICATORS,
  YEARS,
  LOCATIONS,
  CHART
}
