import capitalImage from './capital.png';


const availableIcons = {
  capital: capitalImage
}

const addIcons = (map, icons = ['capital']) => {
  icons.forEach((iconName)=> {
    if(availableIcons[iconName]) {
      map.loadImage(availableIcons[iconName],(error, image) => {
        if (error) { console.error("IMG:", error); }
        map.addImage(`${iconName}-icon`, image);
      });
    }
  })
};

export { addIcons };
