import React, { Component } from 'react';
import RemoteSelect from '../../ui/RemoteSelect';
import { gql } from '@apollo/client';

import HistoryChart from './HistoryChart';

class HistoricalSeries extends Component{
  state = {
    indicatorId: null,
    indicator: null,
    years: null,
    ids: null
  }

  get yearVariables() {
    const { indicatorId } = this.state;

    if(indicatorId) {
      return {...this.props.defaultVariables, indicatorId };
    }

    return {};
  }

  get locationVariables() {
    return this.props.defaultVariables;
  }

  get indicatorsVariables() {
    return this.props.defaultVariables;
  }

  get chartVariables() {
    const {
      indicatorId,
      years,
      ids
    } = this.state;

    return {
      ...this.props.defaultVariables,
      years: years.map(({ value }) => ( parseInt(value) )),
      ids: ids.map(({ value }) => ( parseInt(value) )),
      indicatorId: parseInt(indicatorId)
    };
  }

  queryFor(key) {
    return this.props.query[key];
  }

  queryName(key){
    const { queryPrefix } = this.props;

    return `${queryPrefix}${key}`;
  }

  handleChange(target, value) {
    let payload = {
      [target]: value
    };

    if(target === 'indicator') {
      payload = {
        ...payload,
        indicatorId: parseInt(value.value),
        years: null
      };
    }

    this.setState(payload);
  }

  renderChart() {
    const {
      indicatorId,
      years,
      ids
    } = this.state;

    if(indicatorId && years && years.length > 0 && ids && ids.length > 0) {
      return <HistoryChart
        variables={this.chartVariables}
        query={this.queryFor('chart')}
        queryName={this.queryName('Chart')}
      />
    }

    return (
      <div className="mobilidados-platform-historical-series__chart-placeholder">
        <div>Selecione um indicador</div>
      </div>
    )
  }

  render() {
    const {
      indicator,
      indicatorId,
      years,
      ids
    } = this.state;

    return (
      <div className="mobilidados-platform-historical-series">
        <div className="mobilidados-platform-historical-series__filter">
          <div className="mobilidados-platform-historical-series__filter-item">
            <label>Indicador</label>

            <RemoteSelect
              values={indicator}
              onChange={this.handleChange.bind(this, 'indicator')}
              variables={this.indicatorsVariables}
              query={this.queryFor('indicators')}
              queryName={this.queryName('Indicators')}
            />
          </div>

          <div className="mobilidados-platform-historical-series__filter-item">
            <label>Anos</label>

            <RemoteSelect
              values={years}
              onChange={this.handleChange.bind(this, 'years')}
              variables={this.yearVariables}
              selectAll={true}
              isMulti={true}
              query={this.queryFor('years')}
              queryName={this.queryName('Years')}
            />
          </div>

          <div className="mobilidados-platform-historical-series__filter-item">
            <label>{this.props.locationLabel}</label>

            <RemoteSelect
              values={ids}
              onChange={this.handleChange.bind(this, 'ids')}
              variables={this.locationVariables}
              selectAll={true}
              isMulti={true}
              query={this.queryFor('locations')}
              queryName={this.queryName('Locations')}
            />
          </div>
        </div>

        <div className="mobilidados-platform-historical-series__chart">
          {this.renderChart()}
        </div>
      </div>
    );
  }
}

export default HistoricalSeries;
