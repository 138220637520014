const defaultConfig = {
  compactDisplay: 'long',
  notation: 'compact',
  unitDisplay: 'long'
};

const defaultLocale = 'pt-BR';

const numberFormat = (number, config = defaultConfig, locale = defaultLocale) => {
  return Intl.NumberFormat(locale, defaultConfig).format(number)
};

exports.numberFormat = numberFormat;
